<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />-->
<!--          <b-button
                variant="primary"
                :to="{ name: 'apps-inventoryorderdetailmodify-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>-->

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  style="margin: 5px"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="exportExcel"
              >
                <span class="align-middle">导出</span>
              </b-button>

            </div>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal"
          >
          <b-row>
            <b-col
                cols="12"
                md="3"
            >
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="盘点单编号:"
                  label-for="inventory_no_val"
                  label-size="sm"
              >
                <b-form-input
                    id="inventory_no_val"
                    size="sm"
                    v-model="inventoryNoVal"
                    class="d-inline-block mr-1"
                    placeholder="点击搜索盘点单编号"
                />
              </b-form-group>
            </b-col>

            <b-col
                cols="12"
                md="3"
            >
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="仓库名称:"
                  label-for="warehouse_name_val"
                  label-size="sm"
              >
                <b-form-input
                    id="warehouse_name_val"
                    size="sm"
                    v-model="warehouseNameVal"
                    class="d-inline-block mr-1"
                    placeholder="点击搜索仓库名称"
                />
              </b-form-group>
            </b-col>

            <b-col
                cols="12"
                md="3"
            >
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="商品名称:"
                  label-for="name_val"
                  label-size="sm"
              >
                <b-form-input
                    id="name_val"
                    size="sm"
                    v-model="nameVal"
                    class="d-inline-block mr-1"
                    placeholder="点击搜索商品名称"
                />
              </b-form-group>
            </b-col>

            <b-col
                    cols="12"
                    md="3"
            >
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="申请单状态"
                      label-for="allot_type"
                      label-size="sm"
              >
                <v-select
                        id="allot_type"
                        :options="getCodeOptions('inventory_modify_status')"
                        :clearable="false"
                        v-model = "status_label"
                        class="select-size-sm"
                        @input="changeProductStatus($event)"
                        placeholder="选择状态"
                />
              </b-form-group>
            </b-col>


            <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-inventoryorderdetailmodify-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{getCodeLabel('inventoryorderdetailmodify_type', data.item.inventoryorderdetailmodify_type)}}
        </template>

        <template #cell(code)="data">
          {{data.item.code}}
        </template>

        <template #cell(change_type)="data" >
<!--            <span v-if="data.item.change_type === 1">
              增加
            </span>
          <span v-else>
              减少
            </span>-->
          {{getCodeLabel('stockmodifytype', data.item.change_type)}}
        </template>

        <template #cell(name)="data">
          [{{data.item.product_id}}]{{data.item.name}}
        </template>

        <template #cell(update_time)="data">
          {{toTime(data.item.update_time)}}
        </template>


        <template #cell(total_quantity)="data">
          {{data.item.ext['total_quantity']}}
        </template>
        <template #cell(true_quantity)="data">
          {{data.item.ext['true_quantity']}}
        </template>
        <template #cell(inbound_quantity)="data">
          {{data.item.ext['inbound_quantity']}}
        </template>
        <template #cell(outbound_quantity)="data">
          {{data.item.ext['outbound_quantity']}}
        </template>

        <template #cell(after_quantity)="data">
          {{data.item.ext['after_quantity']}}
        </template>







        <template #cell(updator)="data">
          {{getCodeLabel('user', data.item.updator)}}
        </template>

        <template #cell(status)="data">
          {{getCodeLabel('inventory_modify_status', data.item.status)}}
        </template>

        <template #cell(stock_status)="data">
          {{ getCodeLabel("stock_status", data.item.stock_status)}}
        </template>

        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'inventoryorderdetailmodify'"
                             :object_id="0"
                             :readonly="true"
          />
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <div v-if="roleId == 9 || roleId == 10 || roleId == 17 || roleId == 18 || roleId == 13 || roleId == 14">
              <span v-if="data.item.status === 1">
                <b-dropdown-item @click="updateModifyStatus(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">提交审核</span>
                </b-dropdown-item>

                <b-dropdown-item :to="{ name: 'apps-inventoryorderdetailmodify-edit', query: { id: data.item.id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">编辑</span>
                </b-dropdown-item>
              </span>

              <span v-if="data.item.status === 2">
                <b-dropdown-item :to="{ name: 'apps-inventoryorderdetailmodify-view', query: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">浏览</span>
                </b-dropdown-item>
              </span>
            </div>
            <!--       </b-dropdown>

                   <b-dropdown
                       variant="link"
                       no-caret
                       v-if="roleId == 11 || roleId == 12 || roleId == 13 || roleId == 14"
                   >-->

            <div v-if="roleId == 11 || roleId == 12 || roleId == 14">
               <span v-if="data.item.status != 3">
                <b-dropdown-item @click="changeStatus(data.item, 1)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">审核通过</span>
                </b-dropdown-item>
                <b-dropdown-item @click="changeStatus(data.item, 2)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">驳回</span>
              </b-dropdown-item>
            </span>
              <span class="align-middle ml-50" v-else>
                无操作
              </span>
            </div>
          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import inventoryorderdetailmodifyUseList from './inventoryorderdetailmodifyUseList'
import inventoryorderdetailmodifyStore from './inventoryorderdetailmodifyStore'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
  },
  directives: {
    Ripple,
  },
  props: {
    inventoryId: {
      type: Number,
    },
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('inventoryorderdetailmodify/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition(){
      this.refetchData()
    },

    resetCondition() {
      this.inventoryNoVal = ''
      this.nameVal = ''
      this.warehouseNameVal = ''
      this.status_label = ''
      this.statusVal=''
      this.refetchData()
    }
  },
  data() {
    return  {
      roleId: '',
      advanced_search_modal : false,
      status_label:"",
      inventoryNoVal: '',
    //  warehouseNameVal: '',
    //  nameVal: '',
    }
  },
  setup(props) {
    let toast = useToast()

    // Register module
    if (!store.hasModule('inventoryorderdetailmodify')) store.registerModule('inventoryorderdetailmodify', inventoryorderdetailmodifyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inventoryorderdetailmodify')) store.unregisterModule('inventoryorderdetailmodify')
    })

    const updateModifyStatus = function(item) {
      item.status = 2
      axios.post('/api/inventoryorderdetailmodify/updateStatus', item).then(res => {
        toast.success("保存成功！")
        this.refetchData()
      })
    }

    const changeProductStatus = function (event) {
      if (event != null) {
        this.status_label = event.label
        this.statusVal = event.value
      }
    }


    const changeStatus = function(item, status) {
      if (status === 1) {
        // 审核通过
        item.status = 3
        axios.post('/api/inventoryorderdetailmodify/updateStatus', item).then(res => {
          if (res.data.code === 0) {
            toast.success("保存成功！")
            this.refetchData()
          }else {
            item.status = 2
            toast.error("保存失败！")
          }
        })
      } else if (status === 2) {
        item.status = 1
        axios.post('/api/inventoryorderdetailmodify/updateStatus', item).then(res => {
          if (res.data.code === 0) {
            toast.success("保存成功！")
            this.refetchData()
          } else {
            toast.error("保存失败！")
          }
        })
      }
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }
    const exportExcel = function () {
      //this.inventoryIdVal
      store.dispatch('inventoryorderdetailmodify/exportExcel', {inventoryid:this.inventoryIdVal}).then(res => {
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "盘点单明细——库存调整申请列表导出Excel";
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      inventoryIdVal,
      inventoryNoVal,
      warehouseNameVal,
      nameVal,
      statusVal,

      // UI
    } = inventoryorderdetailmodifyUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      inventoryIdVal,
      updateModifyStatus,
      changeStatus,
      advanced_search,
      changeProductStatus,
      inventoryNoVal,
      warehouseNameVal,
      nameVal,
      statusVal,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      exportExcel,
    }
  },
  created() {
    this.inventoryIdVal = this.$route.query.inventoryId;
    var that = this
    that.roleId = JSON.parse(localStorage.getItem('userData')).role_id
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
