import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function inventoryorderdetailmodifyUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)


  // Table Handlers
  const tableColumns = [
    { key: 'modify_id', label: 'ID', sortable: true },
    //{ key: 'inventory_id', label: '盘点单ID', sortable: true },
    { key: 'inventory_no', label: '盘点单编号', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    // { key: 'inventory_detailid', label: '盘点点明细ID', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    { key: 'code', label: '商品编码(69码)', sortable: true },
    { key: 'name', label: '名称', sortable: true },
    { key: 'specification', label: '商品规格', sortable: true },
    { key: 'box_quantity', label: '单箱标准数量', sortable: true },
    // { key: 'stock_id', label: '', sortable: true },
    { key: 'stock_status', label: ' 库存状态', sortable: true },
    { key: 'change_type', label: '操作类型', sortable: true },
    { key: 'qty', label: '申请数量', sortable: true },
    { key: 'total_quantity', label: '总数量' },
    { key: 'inbound_quantity', label: '入库调整数',headerTitle:'未做单子的入库数量' },
    { key: 'outbound_quantity', label: '出库调整数',headerTitle:'未做单子的出库数量'},
    { key: 'after_quantity', label: '调整后总数量',headerTitle:'总数量+入库调整数-出库调整数'},
    { key: 'true_quantity', label: '实际盘点数量'},
    { key: 'memo', label: '备注', sortable: true },
    { key: 'attachments', label: '附件', sortable: true },
    { key: 'status', label: '申请单状态 ', sortable: true },
   // { key: 'create_time', label: '添加时间', sortable: true },
   // { key: 'creator', label: '添加人ID', sortable: true },
    { key: 'updator', label: '更新人', sortable: true },
    { key: 'update_time', label: '更新时间', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(50)
  const limitOptions = [25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('modify_id')
  const isSortDirDesc = ref(true)
  const inventoryIdVal = ref('')
  const inventoryNoVal = ref('')
  const warehouseNameVal = ref('')
  const nameVal = ref('')
  const statusVal = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('inventoryorderdetailmodify/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        inventory_id: inventoryIdVal.value,
        inventoryNoVal: inventoryNoVal.value,
        warehouseNameVal: warehouseNameVal.value,
        nameVal: nameVal.value,
        statusVal:statusVal.value,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '盘点单明细_库存调整申请单列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    inventoryIdVal,
    inventoryNoVal,
    warehouseNameVal,
    nameVal,
    statusVal,

    refetchData,

    // Extra Filters
  }
}
